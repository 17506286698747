import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import styled from 'styled-components';
import InjectedCheckoutForm from 'components/checkout';
import { Button, Close } from './modal.css';
import { Dialog } from '@reach/dialog';
import { Link } from 'react-scroll';
import VisuallyHidden from '@reach/visually-hidden';

import '@reach/dialog/styles.css';

const StyledDialog = styled(Dialog)`
  box-sizing: border-box;
  color: #201a56;
  background-color: #f1f1f3 !important;
  display: grid;
  justify-items: center;
  max-width: 420px;
  width: 100% !important;
  padding: 1.5rem !important;
`;

const StyledLink = styled(Link)`
  color: orange;
  cursor: pointer;
  font-size: 1.25rem;
  &:hover {
    color: #201a56;
  }
  margin: 0 2rem;
`;

// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
export default class Modal extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.props.open && this.props.hideModal();
    }
  };

  disableScrolling(open) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  }

  render() {
    const { children, open, showModal, hideModal, monthlyPrice } = this.props;

    if (typeof document !== 'undefined') {
      this.disableScrolling(open);
    }

    return (
      <>
        <Button onClick={showModal}>Buy Now</Button>
        {monthlyPrice ? (
          <span></span>
        ) : (
          <StyledLink to="discount" spy smooth duration={500} offset={-64}>
            *association members receive a 60% discount
          </StyledLink>
        )}
        
        <StyledDialog isOpen={open}>
          <Close onClick={hideModal}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </Close>
          {children}
          <Elements>
            <InjectedCheckoutForm monthlyPrice={monthlyPrice} />
          </Elements>
        </StyledDialog>
      </>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  monthlyPrice: PropTypes.bool,
};
