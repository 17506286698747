import React from 'react';
import PropTypes from 'prop-types';
import { Container, ExternalContainer } from './button.css';

const Button = ({ text = 'Learn More', href, color }) => {
  if (href) {
    return (
      <ExternalContainer color={color} href={href}>
        {text}
      </ExternalContainer>
    );
  } else {
    return (
      <Container
        to="pricing"
        spy
        smooth
        duration={500}
        offset={-69}
        activeClass="active"
        color={color}
      >
        {text}
      </Container>
    );
  }
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  color: PropTypes.string,
};

export default Button;
