import React from 'react';
import { CardElement } from 'react-stripe-elements';

class CardSection extends React.Component {
  render() {
    let elementStyles = {
      base: {
        iconColor: '#8bc8ff',
        color: '#201a56',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#818181',
        },
      },
      invalid: {
        iconColor: 'red',
        color: 'red',
      },
    };

    return (
      <div className="fieldset">
        <div className="row">
          <CardElement
            iconStyle="solid"
            style={elementStyles}
            className="field"
          />
        </div>
      </div>
    );
  }
}

export default CardSection;
