import styled from 'styled-components';
// import { accent } from 'constants/theme';

export const StyledCheckoutForm = styled.form`
  position: relative;
  width: 100%;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

  * {
    font-family: Roboto, Open Sans, Segoe UI, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .fieldset {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: white;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #f5f5f5;
    border-radius: 4px;
  }

  .fieldset .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
  }

  .row + .row {
    border-top: 1px solid #201a56;
  }

  span.label {
    display: block;
    width: 25%;
    min-width: 80px;
    padding: 11px 0;
    color: #201a56;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input,
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #e36212;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

  input {
    width: 100%;
    padding: 11px 15px 11px 0;
    color: ##3c3c3c;
    background-color: transparent;
    -webkit-animation: 1ms void-animation-out;
  }

  input::-webkit-input-placeholder {
    color: ##818181;
  }

  input::-moz-placeholder {
    color: ##818181;
  }

  input:-ms-input-placeholder {
    color: ##818181;
  }

  button {
    display: block;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    background-color: #ffa75a;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #ffc079;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }

  button:active {
    background-color: #f18e51;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #ffa75a;
  }

  button:disabled,
  button[disabled] {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #999999;
  }

  .error svg .base {
    fill: #fff;
  }

  .error svg .glyph {
    fill: #6772e5;
  }

  .error .message {
    color: #fff;
  }

  .success .icon .border {
    stroke: #87bbfd;
  }

  .success .icon .checkmark {
    stroke: #fff;
  }

  .success .title {
    color: #fff;
  }

  .success .message {
    color: #9cdbff;
  }

  .success .reset path {
    fill: #fff;
  }
`;

export const StatusMessage = styled.div`
  margin-top: 1.5rem;
  color: #201a56;
  text-align: center;
`;
