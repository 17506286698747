import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6rem;
  margin: 1rem 0;

  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const PriceSwitch = styled.span`
  margin-right: 1rem;
  margin-left: ${props => (props.right ? '0' : '1rem')};
  text-align: center;
`;
