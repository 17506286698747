import React from 'react';
import PropTypes from 'prop-types';

import { Grid, CopyBlock, Header, Copy, StyledImage } from './feature.css';

const Feature = ({ header, copy, image, position = 'regular' }) => {
  if (position == 'inverted') {
    return (
      <Grid position={position}>
        <StyledImage
          fluid={image ? image.childImageSharp.fluid : {}}
          alt={header}
        />

        <CopyBlock>
          <Header>{header}</Header>
          <Copy>{copy}</Copy>
        </CopyBlock>
      </Grid>
    );
  } else {
    return (
      <Grid position={position}>
        <CopyBlock>
          <Header>{header}</Header>
          <Copy>{copy}</Copy>
        </CopyBlock>

        <StyledImage
          fluid={image ? image.childImageSharp.fluid : {}}
          alt={header}
        />
      </Grid>
    );
  }
};

Feature.propTypes = {
  header: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object,
  position: PropTypes.oneOf(['regular', 'inverted']),
};

export default Feature;
