import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './grid.css';

const Grid = ({ children, columns }) => (
  <Container columns={columns}>
    {children.map((child, i) => (
      <div key={i}>{child}</div>
    ))}
  </Container>
);

Grid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  columns: PropTypes.number,
};

export default Grid;
