import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import { Element } from 'react-scroll';
import Grid from 'components/grid';
import Price from 'components/pricing/price';
import { Container, PriceSwitch } from './pricing.css';

import '@trendmicro/react-toggle-switch/dist/react-toggle-switch.css';

const Pricing = ({ prices }) => (
  <Consumer>
    {({ monthlyPrice, togglePrice }) => (
      <React.Fragment>
        <PriceSwitch left>Annual</PriceSwitch>
        <ToggleSwitch checked={monthlyPrice} onChange={togglePrice} />
        <PriceSwitch right>Monthly</PriceSwitch>
        <Container>
          {prices.map((price, i) => (
            <Price {...price} monthlyPrice={monthlyPrice} key={i} />
          ))}
        </Container>
        <Element name="discount">
          <Grid columns={4}>
            <a href="https://www.nrca.ca/">
              <img
                src="https://www.bccassn.com/media/rca-nrca.png"
                alt="NRCA"
              />
            </a>
            <a href="https://www.sicabc.ca/">
              <img
                src="https://www.bccassn.com/media/rca-sica.png"
                alt="SICA"
              />
            </a>
            <a href="https://www.vicabc.ca/">
              <img
                src="https://www.bccassn.com/media/vica_logo_2018.png"
                alt="VICA"
              />
            </a>
            <a href="https://www.vrca.ca/">
              <img
                src="https://www.bccassn.com/media/rca-vrca.png"
                alt="VRCA"
              />
            </a>
          </Grid>
          <br />
          <h1>
            Are you a member of one of the Construction Associations above?
          </h1>
          <p>
            <a href="https://www.bccassn.com/membership#rca">
              Contact your regional association
            </a>{' '}
            to receive a <strong>60% discount on BidCentral Premium</strong> (for annual purchases only)!
          </p>
        </Element>
      </React.Fragment>
    )}
  </Consumer>
);

Pricing.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Pricing;
