import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';

export const PriceBlock = styled.div`
  display: block;
  width: 100%;
  margin: 3rem auto;
  text-align: left;
`;

export const PriceDetails = styled.div``;

export const Title = styled.h6`
  border-bottom: 1px solid #111;
  color: #111;
  margin-bottom: 2rem;
  padding-bottom: 10px;
  font-family: Heimat, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 20px;
  text-transform: uppercase;
`;

export const Copy = styled.p`
  height: 48px;
  color: #4b4b4b;
`;

export const PriceAmt = styled.span`
  color: ${props => (props.color ? props.color : '#808080')};
  display: inline-block;
  font-size: 3.4rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const FeatureTable = styled.ul`
  margin: 3rem 0;
  padding: 0;
`;

export const Feature = styled.li`
  color: #505050;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 1rem;
`;
