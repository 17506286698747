import React from 'react';

class CustomerSection extends React.Component {
  render() {
    return (
      <div className="fieldset">
        <label className="row" htmlFor="name">
          <span className="label">Name</span>
          <input
            id="name"
            type="text"
            placeholder="Full Name"
            required
            autoComplete="name"
            name="name"
          />
        </label>

        <label className="row" htmlFor="email">
          <span className="label">Email</span>
          <input
            id="email"
            type="email"
            placeholder="Email Address"
            required
            autoComplete="email"
            name="email"
          />
        </label>

        <label className="row" htmlFor="tel">
          <span className="label">Phone</span>
          <input
            id="tel"
            type="phone"
            placeholder="Phone Number"
            required
            autoComplete="tel"
            name="tel"
          />
        </label>

        <label className="row" htmlFor="address">
          <span className="label">Address</span>
          <input
            id="address"
            type="text"
            placeholder="Address"
            required
            autoComplete="street-address"
            name="address"
          />
        </label>

        <label className="row" htmlFor="city">
          <span className="label">City</span>
          <input
            id="city"
            type="text"
            placeholder="City"
            required
            autoComplete="address-level2"
            name="city"
          />
        </label>

        <label className="row" htmlFor="country">
          <span className="label">Country</span>
          <input
            id="country"
            type="text"
            placeholder="Country"
            required
            autoComplete="country-name"
            name="country"
          />
        </label>

        <label className="row" htmlFor="company">
          <span className="label">Company</span>
          <input
            id="company"
            type="text"
            placeholder="Company Name"
            required
            autoComplete="company"
            name="company"
          />
        </label>
      </div>
    );
  }
}

export default CustomerSection;
