import styled from 'styled-components';
import Image from 'gatsby-image';
import MEDIA from 'helpers/mediaTemplates';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-gap: 2rem;
  padding: 0;
  margin: 2rem 0;

  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const IntroBlock = styled.div`
  align-self: center;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  font-weight: 300;
  color: white;
`;

export const Subtitle = styled.h4`
  font-size: 1.4rem;
  color: white;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
`;

export const Copy = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  color: #b2bcc1;
  line-height: 1.6;
  letter-spacing: 1px;
  margin-top: 2rem;
`;

export const StyledImage = styled(Image)`
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.3);
`;
