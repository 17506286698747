import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import {
  Grid,
  IntroBlock,
  Title,
  Subtitle,
  Copy,
  StyledImage,
} from './hero.css';

const Hero = ({ item, size = 'regular' }) => {
  return (
    <Grid size={size}>
      <IntroBlock>
        <Subtitle>{item.subtitle}</Subtitle>
        <Title>{item.title}</Title>
        <Copy>{item.copy}</Copy>
        <Button text={item.cta} color="white" />
      </IntroBlock>

      <StyledImage
        fluid={item.image ? item.image.childImageSharp.fluid : {}}
        alt={item.title}
      />
    </Grid>
  );
};

Hero.propTypes = {
  item: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['large']),
};

export default Hero;
