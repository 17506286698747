import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import Modal from 'containers/modal';
import {
  PriceBlock,
  PriceDetails,
  Title,
  Copy,
  PriceAmt,
  FeatureTable,
  Feature,
} from './price.css';

const FeatureObject = ({ feature, included }) => {
  let checkStyle = {
    display: 'inline-block',
    marginRight: '1rem',
    fontWeight: '700',
    color: included ? 'green' : 'grey',
    width: '1rem',
    textAlign: 'center',
  };

  let featureStyle = {
    color: included ? 'black' : '#9b9b9b',
    letterSpacing: '0.05rem',
  };

  if (included) {
    return (
      <Feature>
        <span style={checkStyle}>✓</span>{' '}
        <span style={featureStyle}>{feature}</span>
      </Feature>
    );
  } else {
    return (
      <Feature>
        <span style={checkStyle}>-</span>{' '}
        <span style={featureStyle}>{feature}</span>
      </Feature>
    );
  }
};

const Price = ({
  category,
  monthly,
  annual,
  copy,
  features,
  cta,
  url,
  featured,
  monthlyPrice,
}) => (
  <PriceBlock>
    <PriceDetails>
      <Title>{category}</Title>
      {monthlyPrice ? (
        <PriceAmt color={featured ? 'green' : 'grey'}>{monthly}</PriceAmt>
      ) : (
        <PriceAmt color={featured ? 'green' : 'grey'}>{annual}</PriceAmt>
      )}
      <Copy>{copy}</Copy>
      <FeatureTable>
        <h3>Features</h3>
        {features.map((feature, i) => (
          <FeatureObject {...feature} key={i} />
        ))}
      </FeatureTable>
      {featured ? (
        <Modal>
          <Title>Purchase BidCentral Premium</Title>
        </Modal>
      ) : (
        <Button text={cta} href={url} />
      )}
    </PriceDetails>
  </PriceBlock>
);

FeatureObject.propTypes = {
  feature: PropTypes.string,
  included: PropTypes.bool,
};

Price.propTypes = {
  category: PropTypes.string,
  monthly: PropTypes.string,
  annual: PropTypes.string,
  copy: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  cta: PropTypes.string,
  url: PropTypes.string,
  featured: PropTypes.bool,
  monthlyPrice: PropTypes.bool,
};

export default Price;
