import React from 'react';
import PropTypes from 'prop-types';
import MegaphoneIcon from '!svg-react-loader!../../images/MegaphoneIcon.svg?name=MegaphoneIcon';
import PlanroomIcon from '!svg-react-loader!../../images/PlanroomIcon.svg?name=PlanroomIcon';
import SearchIcon from '!svg-react-loader!../../images/SearchIcon.svg?name=SearchIcon';
import UpdatesIcon from '!svg-react-loader!../../images/UpdatesIcon.svg?name=UpdatesIcon';
import ContractIcon from '!svg-react-loader!../../images/ContractIcon.svg?name=ContractIcon';
import DirectoryIcon from '!svg-react-loader!../../images/DirectoryIcon.svg?name=DirectoryIcon';

import { Header, Copy } from './feature.css';

const AddtlFeature = ({ title, copy, icon }) => {
  let Icon;

  switch (icon) {
    case 'MegaphoneIcon':
      Icon = MegaphoneIcon;
      break;
    case 'PlanroomIcon':
      Icon = PlanroomIcon;
      break;
    case 'SearchIcon':
      Icon = SearchIcon;
      break;
    case 'UpdatesIcon':
      Icon = UpdatesIcon;
      break;
    case 'ContractIcon':
      Icon = ContractIcon;
      break;
    case 'DirectoryIcon':
      Icon = DirectoryIcon;
      break;
    default:
      Icon = null;
  }

  return (
    <div>
      <Icon style={{ maxWidth: '72px', marginBottom: '1rem' }} />
      <Header>{title}</Header>
      <Copy>{copy}</Copy>
    </div>
  );
};

AddtlFeature.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  icon: PropTypes.string,
};

export default AddtlFeature;
