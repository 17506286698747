// CheckoutForm.js
import React from 'react';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
import CustomerSection from './CustomerSection';
import CardSection from './CardSection';
import { StyledCheckoutForm, StatusMessage } from './checkout.css';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      message: '',
      complete: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(ev) {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Retrieve the user information form the form.
    let ownerInfo = {
      name: ev.target.name.value.trim(),
      address: {
        line1: ev.target.address.value.trim(),
        city: ev.target.city.value.trim(),
        country: ev.target.country.value.trim(),
      },
      email: ev.target.email.value.trim(),
      phone: ev.target.tel.value.trim(),
    };

    let company = ev.target.company.value.trim() || "";

    // Disable the Pay button to prevent multiple click events.
    this.setState({
      isProcessing: true,
      message: 'Processing Payment...',
    });

    let result = {};

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    try {
      result = await this.props.stripe.createSource({
        type: 'card',
        owner: ownerInfo,
      });

      let plan = this.props.monthlyPrice
        ? 'plan_E0j3CVbM9vrAsf' // monthly
        : 'plan_E0j3SA88i6Z9TX'; // annual

      // Attach the Source to a new Customer
      const response = await fetch(
        'https://m735szuq80.execute-api.us-west-1.amazonaws.com/production/checkout',
        {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            plan,
            customer: result.source.owner,
            company: company, // include company for customer metadata
            source: result.source,
          }),
        }
      );
      const data = await response.json();

      if (response.status === 200) {
        this.setState({
          isProcessing: true,
          message:
            'Thank you for purchasing BidCentral Premium! Your account will be set up within 24 hours.',
        });
      } else if (response.status === 500) {
        // SOMETHING WENT WRONG
        this.setState({
          isProcessing: false,
          message: "We're sorry. Your card was declined.",
        });
      } else {
        this.setState({
          isProcessing: false,
          message: 'Payment error. Please contact theteam@bidcentral.ca',
        });
      }

      console.log('handleSubmit');
      console.log(data);
    } catch (error) {
      console.log(`Error from handleSubmitFunction: ${error}`);
      this.setState({
        isProcessing: false,
        message: 'Something went wrong. Contact theteam@bidcentral.ca',
      });
    }
  }

  render() {
    const { monthlyPrice } = this.props;

    return (
      <StyledCheckoutForm onSubmit={this.handleSubmit}>
        <CustomerSection />
        <CardSection />
        <button disabled={this.state.isProcessing}>
          Pay {monthlyPrice ? '$79/month + GST' : '$875/year + GST'}
        </button>
        <StatusMessage>{this.state.message}</StatusMessage>
      </StyledCheckoutForm>
    );
  }
}

CheckoutForm.propTypes = {
  stripe: PropTypes.any,
  monthlyPrice: PropTypes.bool,
};

export default injectStripe(CheckoutForm);
