import styled from 'styled-components';
import { Link } from 'react-scroll';
import { accent } from 'constants/theme';

export const Container = styled(Link)`
  display: inline-block;
  padding: 1.2rem 2.4rem;
  background-color: ${props => (props.color ? props.color : accent)};
  border: 2px solid;
  border-color: ${props => (props.color ? props.color : accent)};
  border-radius: 100px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  font-family: 'Montserrat';
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${props => (props.color ? accent : 'white')};
  margin: 1.2rem 1rem 1.2rem 0;
  transition: 0.2s;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.color ? props.color : accent)};
    border-color: ${props => (props.color ? props.color : accent)};
    transform: translateY(-2px);
  }
`;

export const ExternalContainer = styled.a`
  display: inline-block;
  padding: 1.2rem 2.4rem;
  background-color: ${props => (props.color ? props.color : accent)};
  border: 2px solid;
  border-color: ${props => (props.color ? props.color : accent)};
  border-radius: 100px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  font-family: 'Montserrat';
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  color: ${props => (props.color ? accent : 'white')};
  margin: 1.2rem 1rem 1.2rem 0;
  transition: 0.2s;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.color ? props.color : accent)};
    border-color: ${props => (props.color ? props.color : accent)};
    transform: translateY(-2px);
  }
`;
