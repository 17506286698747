import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Section from 'components/section';
import Container from 'components/container';
import Hero from 'components/hero';
import Title from 'components/title';
import Feature from 'components/feature';
import AddtlFeature from 'components/feature/addtlfeature';
import Grid from 'components/grid';
import Pricing from 'components/pricing';
import { graphql } from 'gatsby';

// THEME BASED OFF: http://ydirection.com/Label/index-3.html#

const Index = ({ data }) => (
  <Layout>
    <Section color="primary" name="intro">
      <Container>
        <Hero item={data.homeJson.hero} />
      </Container>
    </Section>
    <Section name="about">
      <Container>
        <Title as="h1" size="large">
          Procurement has never been easier
        </Title>
        {data.homeJson.features.map((item, i) => (
          <Feature {...item} key={i} />
        ))}
      </Container>
    </Section>
    <Section color="secondary" align="center" name="features">
      <Container>
        <Title as="h1" size="large">
          Find your next opportunity
        </Title>
        <Grid columns={3}>
          {data.homeJson.biddingFeatures.map((item, i) => (
            <AddtlFeature {...item} key={i} />
          ))}
        </Grid>
        <br />
        <Title as="h1" size="large">
          Manage your projects
        </Title>
        <Grid columns={3}>
          {data.homeJson.projectFeatures.map((item, i) => (
            <AddtlFeature {...item} key={i} />
          ))}
        </Grid>
      </Container>
    </Section>
    <Section color="primary" align="center">
      <Container>
        <Title as="h1" size="large">
          Here is a sneak peak
        </Title>
        <iframe
          title="Getting Started with Premium"
          width="640"
          height="360"
          src="https://www.youtube.com/embed/wGJUKgWQmyg"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          style={{ marginTop: '3rem' }}
        />
        <p style={{ marginTop: '1rem' }}>
          Have a look at BidCentral Premium in this 3 minute video
        </p>
      </Container>
    </Section>
    <Section align="center" name="pricing">
      <Container>
        <Title as="h1" size="large">
          Our Pricing Plans
        </Title>
        <Pricing {...data.homeJson.pricing} />
      </Container>
    </Section>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      hero {
        title
        subtitle
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cta
      }
      features {
        header
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        position
      }
      biddingFeatures {
        icon
        title
        copy
      }
      projectFeatures {
        icon
        title
        copy
      }
      pricing {
        prices {
          category
          monthly
          annual
          copy
          features {
            feature
            included
          }
          cta
          url
          featured
        }
      }
    }
  }
`;
