import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Image from 'gatsby-image';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.position == 'inverted' ? '7fr 4fr' : '4fr 7fr'};
  grid-gap: 2rem;
  padding: 0;
  margin: 0;

  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const CopyBlock = styled.div`
  align-self: center;
`;

export const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 2rem;
`;

export const Copy = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6;
  color: #898989;
`;

export const StyledImage = styled(Image)`
  margin: 5rem;
  border: 1px solid #ccc;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.15);

  ${MEDIA.TABLET`
    margin: 0;
  `};
`;
